/*
  css for desktop
*/
.container-banner{
  position: fixed;
  top: 0;
  width: 100%;
  margin-top: 15px;
  margin-left: 10px;
}

.container-carousel{
  /* style={{'marginTop': '30px', 'display': 'block', 'marginLeft': '45%', 'marginRight': 'auto', 'height':'25%','width': '25%'}} */
  margin-top: 30px;
  display: 'block';
  margin-left: 45%;
  margin-right: auto;
  height: 25%;
  width: 25%;
}

.logo-1{  
  width: 250px;
  height: 250px;
  /* justify-content: center; */
  /* align-self: center; */
}

.container-services{
  display: flex;
  margin-left: 265px;
  /* margin-bottom: 1599 px; */
}


.container-footer{
  position: fixed;
  left:0;
  bottom:0;
  width: 100%
}

.container-partners{    
  background-color: silver;
  align-items: flex-end;
}
.partners-title{
  align-self: flex-start;    
  font-family: Brush Script MT; 
  font-size:20px;
}
.partners{
  display: flex;
  justify-content: space-around;
  
}
.partners1{
  align-self: flex-start;
  color:#5440d8; 
  font-weight: bold; 
  font-family : PT Mono; 
  font-size:65px; 
  text-decoration: none;
  margin-top: 30px;
}
.partners2{
  width: 250px;
  margin-top: 30px;
}
.partners3{
  width: 100px;  
  margin-bottom: 40px;
}


/* CSS for Tablet */

@media(max-width: 1024px){

  .container-banner{    
    /* position: fixed; */
    /* top: 0; */
    /* width: 100%;
    margin-bottom: 100%; */
  }
  
  .logo-1{    
    width: 250px;
    /* justify-content: center; */
    /* align-self: center; */
  }
  
  .container-services{
    display: flex;
    margin-left: 250px;

    /* margin-bottom: 1599 px; */
  }
  
  
  .container-footer{
    position: fixed;
    left:0;
    bottom:0;
    width: 100%
  }
  
  .container-partners{    
    background-color: silver;
    align-items: flex-end;
  }
  .partners-title{
    align-self: flex-start;    
    font-family: Brush Script MT; 
    font-size:20px;
  }
  .partners{
    display: flex;
    justify-content: space-around;
    
  }
  .partners1{
    align-self: flex-start;
    color:#5440d8; 
    font-weight: 'bold'; 
    font-family : PT Mono; 
    font-size:60px; 
    text-decoration: none;
    margin-top: 30px;
  }
  .partners2{
    width: 250px;
    margin-top: 45px;
  }
  .partners3{
    width: 125px;   
  }
}


/*CSS for mobile*/
@media(max-width:500px){
  .container-banner{
    display:block;
    margin-left: 111px;    
    width: 50%;
    position: absolute;
  }

  .logo-1{    
    width: 150px;
    height: 150px;
    /* justify-content: center; */
    /* align-self: center; */
  }
  .container-carousel{
    /* style={{'marginTop': '30px', 'display': 'block', 'marginLeft': '45%', 'marginRight': 'auto', 'height':'25%','width': '25%'}} */
    margin-top: 47%;
    display: 'block';
    margin-left: 17%;    
    width: 65%;
  }
  .container-services{
    display: flex;
    /* margin-bottom: 1599 px; */
    flex-direction: column;
    margin-left: 20px;
  }
  

  .container-footer{
    position: fixed;
    left:0;
    bottom:0;
    width: 100%
  }

  .container-partners{    
    background-color: silver;
    align-items: flex-end;
  }
  .partners-title{
    align-self: flex-start;
    font-family: Brush Script MT !important; 
    font-size:15px;    
  }
  .partners{
    display: flex;
    justify-content: space-around;
    
  }
  .partners1{
    align-self: flex-start;
    color:#5440d8; 
    font-weight: 'bold'; 
    font-family : PT Mono !important; 
    font-size: 40px; 
    text-decoration: none;
    
  }
  .partners2{
    width: 120px;
  }
  .partners3{
    width: 90px; 
    margin-bottom: 10px;   
  }
}



